<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#">Website</b-breadcrumb-item>
                <b-breadcrumb-item href="#" active>Partners Logo</b-breadcrumb-item>
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-row>
                <b-col md="4">
                    <b-card type="default" header-classes="bg-transparent">
                        <b-form style="text-align: left">
                            <b-row>
                                <b-col md="12">
                                    <label>Partners Logo Name *</label>
                                    <b-input type="text" placeholder="Add Partners Name" v-model="partners.name"></b-input>
                                </b-col>
                                <b-col md="12" class="mt-4">
                                    <label>Partners Logo Url *</label>
                                    <b-input type="text" placeholder="Partners Url" v-model="partners.url"></b-input>
                                </b-col>
                                <b-col md="12" class="mt-4">
                                    <base-button block type="primary" @click="addPartnersLogo">Add Partners Logo</base-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-card>
                </b-col>
                <b-col md="8">
                    <b-card type="default" header-classes="bg-transparent">
                        <!-- <b-row  class="mb-2">
                            <b-col md="8"></b-col>
                            <b-col md="4"><base-button block type="primary">Export to CSV</base-button></b-col>
                        </b-row> -->
                        <vue-good-table
                        :columns="columns"
                        :rows="rows"
                        :line-numbers="true"
                        :search-options="{
                        enabled: true,
                        placeholder: 'Search this table',
                        }"
                        :pagination-options="{
                        enabled: true,
                        mode: 'records'
                        }"
                        >
                        <template slot="table-row" slot-scope="props" style="w">
                          <span v-if="props.column.field == 'before'">
                            <img :src="props.row.partners_url" width="100%" />
                          </span>
                            <span v-if="props.column.field == 'after'">
                                <base-button
                                icon type="primary"
                                size="sm"
                                @click="patnerEdit(props.row.partners_id)"
                                >
                                <span class="btn-inner--icon"><i class="far fa-edit"></i></span>
                                </base-button>
                                <base-button
                                icon type="danger"
                                size="sm"
                                @click="checkedClick(props.row.partners_id)"
                                >
                                <span class="btn-inner--icon">
                                  <i class="ni ni-check-bold" v-if="props.row.partners_active"></i>
                                  <i class="ni ni-fat-remove" v-else></i>
                                </span>
                                </base-button>
                            </span>
                            <span v-else>
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>
                        </vue-good-table>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import { API_URL } from '../../../router/api'
export default {
  name: 'webiste-image-upload',
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data () {
    return {
      isActive: false,
      checked: true,
      columns: [
        {
          label: 'Partners Logo Url',
          field: 'before',
          width: '150px',
        },
        {
          label: 'Partners Logo Name',
          field: 'partners_name'
        },
        {
          label: 'Actions',
          field: 'after'
        }
      ],
      partners: {
          id:null,
          name: null,
          url: null,
      }
    }
  },
  computed: {
    rows () {
      return this.$store.state.partnerList == null ? [] : this.$store.state.partnerList
    }
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    addPartnersLogo() {
      const self = this;
      console.log(this.partners);
      const api = {
      callback() {
          self.axios.post(API_URL+'admin_partners',self.partners)
          .then((response) => {
            let obj = response.data
            obj.status === true ? self.$store.dispatch('alertSuccess', obj.message) : self.$store.dispatch('alertError', obj.message)
            self.$store.dispatch('getPartnerShow')
            self.partners = {};
            
          })
        }
      }
      this.partners.name == null || this.partners.name == '' ? self.$store.dispatch('alertError', "Select Partner Name")
      : this.partners.url == null || this.partners.url == '' ? self.$store.dispatch('alertError', "Partner Url Empty")
      : api.callback()
    },
    checkedClick(id) {
      this.rows.map(row => {
        if(row.partners_id === id) {
          row.partners_active = !row.partners_active; 
          let active = row.partners_active;
          this.axios.post(API_URL+'admin_partners_active',{id: id, active: active})
          .then((res) => {
            let status = res.data.status;
            let message = res.data.message;
            if(status == true) {
              this.$store.dispatch('getPartnerShow');
              this.$store.dispatch('alertSuccess', message)
            }else {
              this.$store.dispatch('alertSuccess', message)
            }
          })
        }
        return row
      })
    },
    patnerEdit(id) {
      let partnerShow = this.$store.state.partnerList == null ? []
      : this.$store.state.partnerList.find((data) => {
        return data.partners_id === id
      })
      this.partners = {
        id : partnerShow.partners_id,
        name: partnerShow.partners_name,
        url: partnerShow.partners_url
      }
    }
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
</style>
